import vehicles from '../../dashboard/vehicles/index.vue';
import fileM from '../../dashboard/vehicles-file-management/index.vue'
import vehicleOptions from '../../dashboard/vehicles-options/index.vue';
import vehicleOptions2 from '../../dashboard/vehicles-secondary-options/index.vue';
import vehicleDsg from '../../dashboard/vehicles-dsg/index.vue';
import vehicleDsgOptions from '../../dashboard/vehicles-dsg-options/index.vue';
import vehicleDsgOptions2 from '../../dashboard/vehicles-dsg-secondary-options/index.vue';
import vehiclePricing from '../../dashboard/vehicles-pricing/index.vue';
import vehicleCombos from '../../dashboard/vehicles-combos/index.vue';

import Vue from 'vue';

export default Vue.extend({
  name: 'Credits-dash',
  components: {
    vehicles,
    fileM,
    vehicleOptions,
    vehicleOptions2,
    vehicleDsg,
    vehicleDsgOptions,
    vehicleDsgOptions2,
    vehiclePricing,
    vehicleCombos
  },
  data: () => ({
    btnSelected: 'fileM',
    btnOptions: [
      { text: 'File Manager', value: 'fileM' },
      { text: 'Vehicles', value: 'vehicles' },
      { text: 'Combos', value: 'combos' },
      { text: 'Vehicle Options', value: 'vehicleOptions' },
      { text: 'Vehicle Secondary Options', value: 'vehicleOptions2' },
      { text: 'Vehicle DSG', value: 'vehicleDsg' },
      { text: 'Vehicle DSG options', value: 'vehicleDsgOptions' },
      { text: 'Vehicle DSG Secondary Options', value: 'vehicleDsgOptions2' },
      { text: 'Vehicle Pricing', value: 'vehiclePricing' },
    ]
  }),
  mounted() {
  },
  methods: {
  },
});
