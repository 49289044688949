import Vue from 'vue';
import {mapMutations} from 'vuex';

import {get, post} from '@/services/api';
import {getExcelFile, updateFieldsStatus, uploadExcelFile} from '../../../services/endpoint.json';
import show_alert_mixin from "@/utils/show_alert_mixin";

export default Vue.extend({
  name: 'vehicles',
  mixins: [show_alert_mixin],
  data: () => ({
    columns: [
      {label: 'Id', key: 'id', sortable: true},
      {label: 'Make', key: 'make', sortable: true},
      {label: 'Model', key: 'model', sortable: true},
      {label: 'Generation', key: 'generation', sortable: true},
      {label: 'Engine', key: 'engine', sortable: true},
      {label: 'EngineCode', key: 'engineCode', sortable: true},
      {label: 'Dsg', key: 'dsg', sortable: true},
      {label: 'To check', key: 'toReview', sortable: true},
      {label: 'Status', key: 'status', sortable: true},
      {label: 'Actions', key: 'actions', sortable: true},
    ],
    file: '',
    tableData: [],
    filter: '',
  }),

  created() {
    this.getDataExcelFileFromDataBase();
  },

  methods: {
    ...mapMutations(['loaderManager', 'notificationManager']),
    resetInputFile(e) {
      e.target.value = '';
    },

    handleClick(id) {
      this.$router.push({
        name: 'view-vehicle',
        params: {id: id}
      });
    },

    getStatusClass(status) {
      const map = {
        Active: 'success',
        Inactive: 'danger',
      };
      return `text-${map[status]}`;
    },

    onSelect(e) {
      this.file = e.target.files.item(0);
    },

    onSubmit() {
      this.loaderManager(true);
      const formData = new FormData();
      formData.append('file', this.file);

      post(uploadExcelFile, formData)
        .then((response) => {
          this.validateResponseUploadExcelFile(response.data);
        })
        .catch(() => {
          this.loaderManager(false);
          this.managerComponentNotification(this.$i18n.t('upload_excel_file.notification_title'), this.$i18n.t('upload_excel_file.notification_error_text'));
        });
    },

    validateResponseUploadExcelFile(dataResponse) {
      if (dataResponse.statusCode === 200) {
        this.file = '';
        this.loaderManager(false);
        this.managerComponentNotification(this.$i18n.t('upload_excel_file.notification_title'), this.$i18n.t('upload_excel_file.notification_success_text'));
        this.getDataExcelFileFromDataBase();
      } else {
        this.loaderManager(false);
        this.managerComponentNotification(this.$i18n.t('upload_excel_file.notification_title'), this.$i18n.t('upload_excel_file.notification_error_text'));
      }
    },

    getDataExcelFileFromDataBase() {
      this.loaderManager(true);
      get(uploadExcelFile)
        .then((response) => {
          this.validateResponseGetDataExcelFileFromDataBase(response.data);
        })
        .catch(() => {
          this.loaderManager(false);
          this.managerComponentNotification(this.$i18n.t('upload_excel_file.notification_title_get_data'), this.$i18n.t('upload_excel_file.notification_error_text_get_data'));
        });
    },

    validateResponseGetDataExcelFileFromDataBase(dataResponse) {
      if (dataResponse.statusCode === 200) {
        this.tableData = dataResponse.data.map(row => ({
          ...row,
          engineCode: JSON.parse(row.engineCode),
          status: row.status ? 'Active' : 'Inactive'
        }));
        this.loaderManager(false);
      } else {
        this.loaderManager(false);
        this.managerComponentNotification(this.$i18n.t('upload_excel_file.notification_title_get_data'), this.$i18n.t('upload_excel_file.notification_error_text_get_data'));
      }
    },

    managerComponentNotification(title, text) {
      this.notificationManager({
        status: true,
        title,
        text,
      });
    },
    async handleDelete(id) {
      const {data} = await post(updateFieldsStatus, {
        id,
        status: 0
      }, true);
      if (Number(data.statusCode) === Number(200)) {
        this.getDataExcelFileFromDataBase();
        this.showAlertNotification('Process Correct')
      } else {
        this.showAlertNotification('Upps have error', 'error')
      }
    },

    async exportExcelDB() {
      this.loaderManager(true);
      this.readInputStatus = !this.readInputStatus;
      if (this.readInputStatus) {
        const {data} = await get(getExcelFile, this.fields, true);
        if (Number(data.statusCode) === Number(200)) {
          this.showModal = false;
          this.loaderManager(false);
          await this.showDownload(data.message, 'success');
          this.readInputStatus = !this.readInputStatus;
        } else {
          this.loaderManager(false);
        }

      }
    },
    async showDownload(msg, type) {
      await this.$swal({
        title: (type === 'success') ? 'Success' : 'Error',
        text: 'Your download is ready!',
        icon: (type === 'success') ? 'success' : 'error',
        showConfirmButton: false,
        timer: 1500,
        confirmButtonColor: '#d14343',
        cancelButtonColor: '#000000',
      });
      window.open(msg, 'blank');
    },

  },
});
