var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-100 table-responsive"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filter),expression:"filter"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Search by make, model and generation"},domProps:{"value":(_vm.filter)},on:{"input":function($event){if($event.target.composing){ return; }_vm.filter=$event.target.value}}}),_c('b-table',{attrs:{"bordered":"","small":"","hover":"","items":_vm.tableData,"fields":_vm.columns,"filter":_vm.filter,"filter-included-fields":['make','model','generation']},scopedSlots:_vm._u([{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('b-btn',{directives:[{name:"tippy",rawName:"v-tippy",value:({ placement : 'top',  arrow: true }),expression:"{ placement : 'top',  arrow: true }"}],staticClass:"btn btn-primary btn-sm",attrs:{"content":'View Data'},on:{"click":function($event){return _vm.handleClick(item.id)}}},[_c('i',{staticClass:"material-icons"},[_vm._v("visibility")])])]}},{key:"cell(engineCode)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.engineCode.join('; '))+" ")]}},{key:"cell(toReview)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.toReview ? 'YES' : 'NO')+" ")]}},{key:"cell(status)",fn:function(ref){
var item = ref.item;
return [_c('span',{class:[_vm.getStatusClass(item.status)]},[_vm._v(_vm._s(item.status))])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }