import Vue from 'vue';
import {mapMutations} from 'vuex';
import {get} from '@/services/api';
import {vehiclesDSGOSecondaryOptions} from '../../../services/endpoint.json';

export default Vue.extend({
  name: 'vehicles-dsg-secondary-options',

  data: () => ({
    columns: [
      {label:'Option Id', key:'optionId', sortable: true},
      {label:'Type', key:'type', sortable: true},
      {label:'Name', key:'name', sortable: true},
      {label:'Description', key:'description', sortable: true},
    ],
    tableData: [],
    file: '',
  }),

  async created() {
    await this.getDataExcelFileFromDataBase();
  },
  methods: {
    ...mapMutations(['loaderManager']),


    async getDataExcelFileFromDataBase() {
      try {
        this.loaderManager(true);
        const {data} = await get(vehiclesDSGOSecondaryOptions, null, true);
        this.loaderManager(false);
        this.tableData = data.message;
      } catch (e) {
        this.loaderManager(false);
      }
    },
  },
});
