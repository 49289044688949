import Vue from 'vue';
import {mapMutations} from 'vuex';
import {get} from '@/services/api';
import {vehiclesDSG} from '../../../services/endpoint.json';

export default Vue.extend({
  name: 'vehicles-dsg',

  data: () => ({
    columns: [
      {label: 'DSG', key: 'DSG', sortable: true},
      {label: 'Stock Dsg Software', key: 'stockDsgSoftware', sortable: true},
      {label: 'Stock Dsg Warnings', key: 'stockDsgWarnings', sortable: true},
      {label: 'Stock Location', key: 'stockLocation', sortable: true},
      {label: 'Stock Price', key: 'stockPrice', sortable: true},
      {label: 'Stock Torque', key: 'stockTorque', sortable: true},
      {label: 'Status', key: 'status', sortable: true}
    ],
    tableData: [],
    file: '',
  }),

  async created() {
    await this.getDataExcelFileFromDataBase();
  },
  methods: {
    ...mapMutations(['loaderManager']),
    async getDataExcelFileFromDataBase() {
      try {
        this.loaderManager(true);
        const {data} = await get(vehiclesDSG, null, true);
        this.loaderManager(false);
        this.tableData = data.message;
      } catch (e) {
        this.loaderManager(false);
      }
    },
  },
});
