import Vue from 'vue';
import {mapMutations} from 'vuex';
import {get} from '@/services/api';
import {vehiclesPrices} from '../../../services/endpoint.json';


export default Vue.extend({
  name: 'vehicles-pricing',

  data: () => ({
    columns: [
      {label: 'Id', key: 'id', sortable: true},
      {label: 'Description', key: 'description', sortable: true},
      {label: 'Price', key: 'price', sortable: true},
    ],
    tableData: [],
    file: '',
  }),

  async created() {
    await this.getDataExcelFileFromDataBase();
  },
  methods: {
    ...mapMutations(['loaderManager']),


    async getDataExcelFileFromDataBase() {
      try {
        this.loaderManager(true);
        const {data} = await get(vehiclesPrices, null, true);
        this.loaderManager(false);
        this.tableData = data.message;
      } catch (e) {
        this.loaderManager(false);
      }
    },
  },
});
