<template>
  <b-modal size="lg" ref="modal_combo" hide-footer no-stacking dialog-class="modal-dialog-centered" :title="title">
    <b-row v-if="isClone">
      <b-col>
        <p class="text-danger">This process clones and creates a new record in the database.
          After cloning the information, it can be updated</p>
      </b-col>
    </b-row>
    <b-row v-if="isClone">
      <b-col>
        <h6>New name combo</h6>
        <b-form-input placeholder="Enter name" v-model="nameTemp"></b-form-input>
      </b-col>
    </b-row>
    <hr v-if="isClone">
    <b-row v-if="!isClone">
      <b-col><h6>RULES</h6></b-col>
    </b-row>
    <b-row>
      <b-col class="table-responsive">
        <table class="table table-bordered">
          <tbody>
          <tr>
            <th>MIN</th>
            <th>MAX</th>
            <th>ECU</th>
            <th v-if="!isClone">OPTION</th>
          </tr>
          <tr v-for="(item, index) in arrayRules">
            <td>{{ item.min }}</td>
            <td>{{ item.max }}</td>
            <td>{{ item.ecu }}</td>
            <td v-if="!isClone">
              <button size="sm" @click="removeRules(index)" class="btn-primary">
                <i class="material-icons">delete</i>
              </button>
              <button size="sm" @click="selectRules(index)" class="btn-primary">
                <i class="material-icons">edit</i>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </b-col>
      <b-col v-if="!isClone">
        <b-form-group id="input-group-2" label="Min" label-for="input-2">
          <b-form-input id="npm_min" placeholder="Enter npm min" v-model="modelRule.min" required></b-form-input>
        </b-form-group>
        <b-form-group id="input-group-2" label="Max" label-for="input-2">
          <b-form-input id="npm_max" placeholder="Enter npm max" v-model="modelRule.max" required></b-form-input>
        </b-form-group>
        <b-form-group id="input-group-2" label="ECU" label-for="input-2">
          <select v-model="modelRule.ecu" name="input-ecu" id="input2-ecu" class="form-control">
            <option :value="item.key" v-for="(item, index) in arrayStages" :key="index">{{ item.value }}</option>
          </select>
        </b-form-group>
        <b-button type="button" @click="saveRule()" variant="primary">SAVE INFORMATION</b-button>
      </b-col>
    </b-row>
    <hr>
    <b-row>
      <b-col><h6>COMBOS</h6></b-col>
    </b-row>
    <b-row v-if="!isClone">
      <b-col>
        <b-form-group id="input-ecu" label="ECU" label-for="input-ecu">
          <select v-model="modelCombo.ecu" name="input-ecu" id="input-ecu" class="form-control">
            <option :value="item.key" v-for="(item, index) in arrayStages" :key="index">{{ item.value }}</option>
          </select>
        </b-form-group>
        <b-form-group id="input-dsg" label="DSG" label-for="input-dsg">
          <select v-model="modelCombo.dsg" name="" id="" class="form-control">
            <option :value="item.key" v-for="(item, index) in arrayStages" :key="index">{{ item.value }}</option>
          </select>
        </b-form-group>
        <label for="">PRICE</label>
        <b-form-input v-model="modelCombo.price" type="text" id="combo_price"></b-form-input>
      </b-col>
      <b-col>

        <b-form-group id="input-title" label="TITLE" label-for="input-title">
          <b-form-input v-model="modelCombo.title" type="text" id="combo_title"></b-form-input>
        </b-form-group>

        <b-form-group id="input-description" label="DESCRIPTION" label-for="input-description">
          <b-form-textarea rows="5" v-model="modelCombo.description" type="text"
                           id="combo_description"></b-form-textarea>
        </b-form-group>
        <b-button type="button" @click="saveCombo()" variant="primary">SAVE INFORMATION</b-button>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col class="table-responsive">
        <table class="table table-bordered">
          <tbody>
          <tr>
            <th v-if="!isClone">ACTION</th>
            <th>ECU</th>
            <th>DSG</th>
            <th>PRICE</th>
            <th>TITLE</th>
            <th>DESCRIPTION</th>
          </tr>
          <tr v-for="(item, index) in arrayCombos" :key="index">
            <td v-if="!isClone">
              <button size="sm" @click="removeCombo(index)" class="btn-primary">
                <i class="material-icons">delete</i>
              </button>
              <button size="sm" @click="selectCombo(index)" class="btn-primary">
                <i class="material-icons">edit</i>
              </button>
            </td>
            <td>{{ item.ecu }}</td>
            <td>{{ item.dsg }}</td>
            <td>{{ item.price }}</td>
            <td>{{ item.title }}</td>
            <td>{{ item.description }}</td>
          </tr>
          </tbody>
        </table>
      </b-col>
    </b-row>
    <b-row class="mt-4" v-if="isClone">
      <b-col class="justify-content-center d-flex">
        <b-button type="button" @click="cloneCombo()" variant="primary">CLONE AND CREATE NEW RECORD IN DATABASE
        </b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>

import {post} from '@/services/api';
import {creditOrderAccept, vehiclesUpdateCombos} from '@/services/endpoint.json';
import show_alert_mixin from "@/utils/show_alert_mixin";


const arrayStages = [
  {key: 1, value: 1},
  {key: 2, value: 2},
  {key: 2.1, value: '2+'},
  {key: 3, value: 3},
  {key: 4, value: 4},
];
export default {
  name: "modalComboView",
  mixins:[show_alert_mixin],
  data: () => ({
    arrayStages: arrayStages,
    title: 'Combos ',
    arrayRules: [],
    rule: {
      min: null,
      max: null,
      value: null
    },
    arrayCombos: [],
    modelRule: {
      min: null,
      max: null,
      ecu: null
    },
    modelCombo: {
      dsg: null,
      ecu: null,
      price: null,
      title: null,
      description: null,
    },
    modelId: null,
    status: 1,
    indexCombo: null,
    indexRule: null,
    selected: '',
    isClone: false,
    name: null,
    nameTemp: null,
  }),
  props: {
    itemSelected: {
      default: null
    },
  },
  methods: {
    open(item, type = 'view') {
      this.isClone = (type === 'clone')
      this.modelId = item._id;
      this.title = (this.isClone) ? 'Combo : ' + item.name : 'Combo: ' + item.name
      this.name = item.name
      this.nameTemp = item.name
      this.arrayRules = item.rules;
      this.arrayCombos = item.combos;
      this.status = item.status;
      this.$refs.modal_combo.show()
    },
    removeRules(index) {
      this.$swal({
        title: 'Are you sure?',
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonColor: "#d14343",
        cancelButtonColor: "#000000",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.arrayRules.splice(index, 1)
          await this.save();
        }
      });
    },
    selectRules(index) {
      this.indexRule = index;
      this.modelRule = this.arrayRules[index];
    },
    removeCombo(index) {
      this.$swal({
        title: 'Are you sure?',
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonColor: "#d14343",
        cancelButtonColor: "#000000",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.arrayCombos.splice(index, 1)
          await this.save();
        }
      });
    },
    selectCombo(index) {
      this.indexCombo = index;
      this.modelCombo = this.arrayCombos[index];
    },
    saveCombo() {
      if (this.validateModelCombo()) {
        if (this.indexCombo !== null) {
          this.arrayCombos[this.indexCombo] = JSON.parse(JSON.stringify(this.modelCombo));
          this.save()
        } else {
          this.arrayCombos.push(this.modelCombo);
          this.save()
        }
      }
    },
    validateModelCombo() {
      return (this.modelCombo.dsg !== null && this.modelCombo.ecu !== null &&
        this.modelCombo.description !== null && this.modelCombo.title !== null &&
        this.modelCombo.price !== null)
    },
    validateModelRule() {
      return (this.modelRule.ecu !== null)
    },
    saveRule() {
      if (this.validateModelRule()) {
        if (this.indexRule !== null) {
          this.arrayRules[this.indexRule] = this.modelRule;
          this.save()
        } else {
          this.arrayRules.push(this.modelRule);
          this.save()
        }
      }
    },
    async save() {
      if (this.modelId !== null) {
        const {data} = await post(vehiclesUpdateCombos, {
          id: this.modelId,
          name: this.name,
          rules: this.arrayRules,
          combos: this.arrayCombos,
          status: this.status,
          isClone: this.isClone
        }, true)
        if(data === 200){
          this.$emit('reloadCombos')
          this.showAlertNotification('Information updated')
          this.clearCombo()
          this.clearRule()
          this.$refs.modal_combo.hide()
        }else{
          this.showAlertNotification('The information could not be saved', 'error')
        }
      }
    },
    updateStatus(item) {
      this.status = (item.status !== undefined) ? Number(!item.status) : 1;
      this.modelId = item._id;
      this.arrayRules = item.rules;
      this.arrayCombos = item.combos;
      this.save();
    },
    clearRule() {
      this.modelRule.ecu = null;
      this.modelRule.max = null;
      this.modelRule.min = null;
    },
    clearCombo() {
      this.modelCombo.price = null;
      this.modelCombo.ecu = null;
      this.modelCombo.dsg = null;
      this.modelCombo.title = null;
      this.modelCombo.description = null;
    },
    async cloneCombo() {
      this.$swal({
        showConfirmButton: true,
        showCancelButton: true,
        title: 'Are you sure?',
        confirmButtonColor: '#d14343',
        cancelButtonColor: '#000000',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      })
        .then(async res => {
          if (res.isConfirmed) {
            this.name = this.nameTemp
            this.save().then()
          }
        });
    }
  }
}
</script>

<style scoped>

</style>
<!--this.$bvModal.show(id)-->
