import Vue from 'vue';
import {get, post} from '@/services/api';
import {vehiclesIndexCombos} from '@/services/endpoint.json';
import ModalComboView from './modalComboView.vue'


export default Vue.extend({
  name: 'Combos',
  data: () => ({
    items: [],
    fields: [
      {
        key: 'name',
        label: 'DSG',
        sortable: true
      },
      {
        key: 'combos',
        label: 'Combos',
        sortable: false
      },
      {
        key: 'status',
        label: 'status',
        sortable: true
      },
      {key: 'actions', label: 'Actions'}
    ],
    itemSelected: null,
  }),
  components: {
    ModalComboView,
  },
  created() {
    this.loadDataCombos().then()
  },
  methods: {
    async loadDataCombos() {
      const {data} = await get(vehiclesIndexCombos)
      this.items = [];
      this.items = data.message
    },
    view(item, type = 'view') {
      this.itemSelected = item
      this.$refs.modal_combo_view.open(item, type)
    },

    remove(_id) {
      this.$refs.modal_combo_view.updateStatus(_id)
    },
  },
});
